import React from "react";
import footerLogo from "../assets/images/png/logo.png";
import mascotCoffee from "../assets/images/svg/mascot-coffee.svg";
import { TwitterIcon, TelegramIcon, GithubIcon } from "./common/Icon";
import performance from "../assets/images/gif/performance.gif";
import FireAnimation from "./common/FireAnimation";

let currentYear = new Date().getFullYear();
const Footer = () => {
  return (
    <>
      <div className="position-relative max_w_1920 ">
        <div className="position-absolute d-none d-lg-block performance_gif performance_right_gif end-0">
          <div className="position-relative">
            <img width={171} src={performance} alt="performance-gif" />
            <div className="position-absolute footer_dots d-none d-xxl-block">
              <FireAnimation />
            </div>
          </div>
        </div>
        <div className="container custom_container position-relative z_5">
          <img
            className="position-absolute d-none d-sm-block mascot_coffee_img"
            width={152}
            height={152}
            src={mascotCoffee}
            alt="mascot-coffee"
          />
          <div className="row justify-content-center  position-relative z_5">
            <div className="col-md-8 col-lg-7 col-xl-6 ">
              <div className="text-center">
                <a href="/" className="mb-3 d-inline-block">
                  <img
                    className="footer_logo"
                    width={200}
                    src={footerLogo}
                    alt="footer logo"
                  />
                </a>
                <p className="text-white fw-normal text_md opacity_08 lh_secondary   pt-sm-1 mb-0 mb-sm-2">
                  A Telegram bot designed to create, mint, and trade runes
                  <span className="d-xl-block">
                    {" "}
                    securely and effortlessly on the Bitcoin blockchain.
                  </span>
                </p>
                <div className="d-flex gap-3 justify-content-center align-items-center py-4 mb-md-3">
                  <a
                    href="https://twitter.com/runigun"
                    target="blank"
                    className="footer_icons d-inline-block"
                  >
                    <TwitterIcon />
                  </a>
                  <a
                    href="https://t.me/RuniGunPortalOfficial"
                    target="blank"
                    className="footer_icons d-inline-block"
                  >
                    <TelegramIcon />
                  </a>
                  <a
                    href="https://docs.runigun.xyz/"
                    className="footer_icons d-inline-block"
                  >
                    <GithubIcon />
                  </a>
                </div>
              </div>
            </div>
            <div className="border_top py-sm-1 text-center  position-relative z_5">
              <p className="text_base fw-normal opacity_08 text-white py-3 mb-0">
                © {currentYear} All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
