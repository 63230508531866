import React from 'react'
import blueDots from "../../assets/images/svg/blue-dots-img.svg";
import greenDots from "../../assets/images/svg/green-dot-img.svg";
import yellowDots from "../../assets/images/svg/yellow-dot-img.svg";
const FireAnimation = () => {
  return (
    <div className="d-flex gap-2 flex-column-reverse gap-lg-4 gap-xl-5">
      <div>
        <img
          className="dot_image six d-none d-md-block "
          src={blueDots}
          alt="blue-dots"
        />
      </div>
      <div>
        <img
          className="dot_image d-none d-md-block five"
          src={greenDots}
          alt="green-dots"
        />
      </div>
      <div>
        <img
          className="dot_image d-none d-md-block four "
          src={yellowDots}
          alt="yellow-dots"
        />
      </div>
      <div>
        <img
          className="dot_image d-none d-sm-block three"
          src={blueDots}
          alt="blue-dots"
        />
      </div>
      <div>
        <img
          className="dot_image two d-none d-sm-block "
          src={greenDots}
          alt="green-dots"
        />
      </div>
      <div>
        <img
          className="dot_image one d-none d-sm-block "
          src={yellowDots}
          alt="yellow-dots"
        />
      </div>
    </div>
  );
}

export default FireAnimation