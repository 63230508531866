import React from "react";
import mascotPondering from "../assets/images/svg/mascot-pondering-rune.svg";
import mascotLoving from "../assets/images/svg/mascot-loving.svg";
import performance from "../assets/images/gif/hero-top-left.gif";
import FireAnimation from "./common/FireAnimation";

const Join = () => {
  return (
    <>
      <div id="contact" className="position-relative max_w_1920">
        <div className="position-absolute join_mascot_gif start-0 d-none d-xl-block">
          <div className="position-relative">
            <img width={151} src={performance} alt="left-mascot" />
            <div className="position-absolute join_dots d-none d-xxl-block">
              <FireAnimation />
            </div>
          </div>
        </div>
        <div className="container custom_container position-relative">
          <div className="join_bg d-flex flex-column align-items-center justify-content-center ps-lg-0 ">
            <img
              className="position-absolute mascot_pondering"
              width={215}
              src={mascotPondering}
              alt="mascot-Pondering"
            />
            <img
              className="position-absolute mascot_loving"
              width={215}
              src={mascotLoving}
              alt="mascot-Pondering"
            />
            <h2 className="sub_heading ff_emulogic fw-normal text-white text-center mb-0  px-4 px-sm-0">
              Join <span className="text_yellow">the Rune</span> Revolution
            </h2>
            <div className="d-flex align-items-center flex-column flex-md-row justify-content-center gap-2 gap-md-4 pt-3 pt-sm-4 pt-lg-5">
              <a
                href="https://t.me/RuniGunBot"
                target="blank"
                className="ff_emulogic bg-transparent d-inline-block common_btn text-black text-uppercase fw-normal text-decoration-none"
              >
                Start Trading Now
              </a>
              <a
                href="https://docs.runigun.xyz"
                className=" text_yellow ff_emulogic d-inline-block text_sm transparent_button py-sm-3 py-2 px-3 px-sm-4  text-uppercase fw-normal  text-decoration-none w_xsm_100 text-center"
              >
                Learn More
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Join;
