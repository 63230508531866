import React from "react";
import multiLevel from "../assets/images/svg/multi-level.svg";
import buySell from "../assets/images/svg/buy-sell.svg";
import cornerLine from "../assets/images/svg/left-corner-line.svg";
import cornerLine2 from "../assets/images/svg/right-corner-line.svg";
import mascot from "../assets/images/svg/mascot-confused.svg";
import revinueGif from "../assets/images/gif/performance.gif";
import FireAnimation from "./common/FireAnimation";
const Revenue = () => {
  return (
    <div className="max_w_1920">
      <div className="revenue_top_spacing position-relative">
        <div className="position-absolute performance_gif performance_right_gif  end-0 d-none d-lg-block">
          <div>
            <img width={171} src={revinueGif} alt="mascot-confused" />
            <div className="position-absolute revenue_right_gif d-none d-xxl-block">
              <FireAnimation />
            </div>
          </div>
        </div>
        <div className="container custom_container position-relative">
          <img
            className="position-absolute mascot_confused_img d-none d-sm-block  "
            width={152}
            height={152}
            src={mascot}
            alt="mascot-confused"
          />
          <h2 className="sub_heading ff_emulogic fw-normal text-white text-center pb-4 mb-0 mb-xl-5">
            Revenue Share and Referrals
          </h2>
          <div className="px-lg-2">
            <div className="row gap-4 gap-md-0 justify-content-center pt-lg-2 px-lg-1 px-xl-0">
              <div className="col-sm-8 col-md-6 col-xl-auto ps-lg-0 ">
                <div className="revenue_card p-4 px-lg-4 position-relative">
                  <img
                    className="position-absolute left_corner_line"
                    width={97}
                    src={cornerLine}
                    alt="left-corner-line"
                  />
                  <img
                    className="position-absolute right_corner_line"
                    width={97}
                    src={cornerLine2}
                    alt="right-corner-line"
                  />
                  <img
                    className="pt-2 pt-sm-0 pt-lg-4 ps-md-2 ps-lg-3 revenue_img"
                    width={63}
                    src={buySell}
                    alt="buy-sell"
                  />
                  <p className="text-white fw-bold ff_disposable_bold text_2xl revenue_para pt-3 py-sm-4 mt-lg-3 ps-md-2 ps-lg-3 mb-0 mb-md-2 mb-lg-1 pb-lg-2">
                    $RNG holders will receive a base fee kickback of 20% of all
                    Buy/ Sell transaction fees executed{" "}
                    <br className="d-none d-lg-block" /> through RuniGun
                  </p>
                </div>
              </div>
              <div className="col-sm-8 col-md-6 col-xl-auto pe-lg-0 ">
                <div className="revenue_card p-4 px-lg-4 position-relative">
                  <img
                    className="position-absolute left_corner_line"
                    width={97}
                    src={cornerLine}
                    alt="left-corner-line"
                  />
                  <img
                    className="position-absolute right_corner_line"
                    width={97}
                    src={cornerLine2}
                    alt="right-corner-line"
                  />
                  <img
                    className="pt-2 pt-sm-0 pt-lg-4 ps-md-2 ps-lg-3 revenue_img"
                    width={63}
                    src={multiLevel}
                    alt="multi-level"
                  />
                  <p className="text-white fw-bold ff_disposable_bold text_2xl revenue_para pt-3 py-md-4 mt-lg-3 ps-md-2 ps-lg-3 mb-0 mb-md-2 mb-lg-3 ">
                    Earn Up to 40% Revenue with Our Multi-Level Referral System
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Revenue;
