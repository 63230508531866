import React from 'react'
import top_left_gif from "../assets/images/gif/hero-top-left.gif"; 
import performance_gif from "../assets/images/gif/performance-gif.gif"
import FireAnimation from './common/FireAnimation';
const Performance = () => {
  return (
    <section className="position-relative max_w_1920">
      <div>
        <div className="position-absolute d-none d-md-block performance_top_left_gif">
          <img src={top_left_gif} alt="taddy" />
          <div className="position-absolute performance_dots d-none d-xxl-block">
            <FireAnimation />
          </div>
        </div>
      </div>
      <div className="container custom_container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6 col-11 col-sm-9 col-md-9">
            <img
              className="w-100 performance_gif"
              src={performance_gif}
              alt="gif"
            />
          </div>
          <div className="col-lg-6">
            <h2 className="ff_emulogic text-white text-center text-lg-start  pb-4 mb-lg-2 mb-0 sub_heading performance_heading pt-3 pt-lg-0">
              Performance and Scalability
            </h2>
            <div className="performace_card">
              <div>
                <ul className="ff_emulogic mb-0 text_yellow text_xsm ps-4">
                  <li className="mb-0">
                    Optimized for Peak Performance with Multiple Bot Instances.
                  </li>
                  <li className="ff_emulogic text_yellow text_xsm my-4">
                    Designed for Scalability, Easy DEX integrations and Quick
                    Response Times
                  </li>
                  <li className="ff_emulogic text_yellow text_xsm mb-0">
                    Cybersecurity at Our Core: From Penetration Testing to GRC
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Performance