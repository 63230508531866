import "./App.css";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Hero from "./components/Hero";
import Feature from "./components/Feature";
import Performance from "./components/Performance";
import Revenue from "./components/Revenue";
import Join from "./components/Join";
import Footer from "./components/Footer";
import BackToTop from "./components/common/BackToTop";
import Header from "./components/Header,";
function App() {

  return (
    <div className="position-relative">
      <BackToTop />
      <Header />
      <Hero />
      <div className="overflow-hidden">
      <Feature />
      <Performance />
      <Revenue />
      <Join />
      <Footer />
      </div>
    </div>
  );
}

export default App;
