import React from "react";
import rightGif from "../assets/images/gif/hero-right-gif.gif";
import leftBottomImg from "../assets/images/png/hero-button-left.png";
import topLeftGif from "../assets/images/gif/hero-top-left.gif";
import FireAnimation from "./common/FireAnimation";
const Hero = () => {
  return (
    <div
      id="home"
      className="hero_h position-relative z-1 d-flex align-items-center justify-content-between"
    >
      <div className="w-100 d-flex justify-content-center align-items-center pt-5 mt-sm-5 mt-4 pt-lg-0 mt-lg-0 position-relative max_w_1920">
        <div className="position-absolute d-none d-md-block top_left_gif">
          <div className="position-relative">
            <img src={topLeftGif} alt="taddy" />

            <div className="position-absolute d-none hero_dots d-xxl-block">
              <FireAnimation />
            </div>
          </div>
        </div>
        <div className="container custom_container d-flex justify-content-center align-items-center mt-md-5 mt-lg-0">
          <div className="col-xl-10 mx-auto position-relative">
            <img
              className="position-absolute d-none d-md-block hero_right_gif"
              src={rightGif}
              alt="taddy"
            />

            <img
              className="position-absolute d-none d-md-block hero_left_img"
              src={leftBottomImg}
              alt="taddy"
            />
            <h1 className="text-center text-white mb-0 text-uppercase hero_heading ff_emulogic">
              A Telegram bot designed to{" "}
              <span className="text_yellow">create, mint, and trade runes</span>{" "}
              securely and effortlessly on the Bitcoin blockchain.
            </h1>
            <div className="d-flex justify-content-center gap-sm-4 gap-3 pt-4 mt-sm-3">
              <a href="https://t.me/RuniGunBot" target="blank">
                <button className="yellow_button py-sm-3 py-2 px-3 px-sm-4 fw-normal text_sm text-uppercase ff_emulogic">
                  Get Started
                </button>
              </a>
              <a href="https://docs.runigun.xyz">
                <button className="text-uppercase py-sm-3 py-2 px-3 px-sm-4 fw-normal text_sm text-uppercase bg-transparent transparent_button ff_emulogic">
                  Learn more
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
