import React from "react";
import telegram from "../assets/images/png/telegram-logo.png";
import bitcoin from "../assets/images/png/bitcoin-logo.png";
import tokenBitcoin from "../assets/images/png/token-with-bitcoin.png";
import treading from "../assets/images/png/treading-logo.png";
import performance from "../assets/images/gif/performance.gif";
import mascotHey from "../assets/images/svg/mascot-hey-icon.svg";
import FireAnimation from "./common/FireAnimation";

const Feature = () => {
  return (
    <div id="features" className="position-relative max_w_1920">
      <div className="position-absolute d-none d-md-block performance_features_gif  performance_right_gif end-0">
        <div className="position-relative"><img width={171} src={performance} alt="performance-gif" />
        <div className="position-absolute feature_dot_gif d-none d-xxl-block"><FireAnimation/></div></div>
      </div>

      <div className="container custom_container feature_section">
        <img
          className="position-absolute  mascot_hey_icon d-none d-lg-block "
          width={171}
          src={mascotHey}
          alt="mascot-hey-img"
        />
        <h2 className="text-center ff_emulogic mb-3 text_xl text-white">
          Our Features
        </h2>
        <p className="text-center text_md text-white opacity_80 mb-0 mb-xl-5 pb-4 feature_para">
          Securely mint, create, and effortlessly trade runes
          <span className="d-sm-block">on the Bitcoin blockchain</span>
        </p>
        <div className="row justify-content-center pt-md-2">
          <div className="col-xl-3 col-sm-10 col-11 col-lg-4 col-md-6">
            <div className="feature_card_box feature_cards p-4 h-100">
              <img
                className="mt-2"
                height={50}
                width={50}
                src={telegram}
                alt="telegram"
              />
              <p className="text-white fw-bold ff_disposable_bold lh_primary  text_lg pb-2 mb-0 mt-3 pt-1">
                Direct Bitcoin Layer{" "}
                <span className="d-lg-block">One and Rune</span> Transactions
                via <span className="d-xl-block">Telegram.</span>
              </p>
            </div>
          </div>
          <div className="col-xl-3 col-sm-10 col-11 col-lg-4 col-md-6 mt-3 mt-md-0 pt-1 pt-md-0">
            <div className="feature_card_box feature_cards p-4 h-100">
              <img
                className="mt-2"
                height={50}
                width={50}
                src={bitcoin}
                alt="telegram"
              />
              <p className="text-white fw-bold ff_disposable_bold lh_primary  text_lg pb-2 mb-0 mt-3 pt-1">
                Runigun runs its own Bitcoin Core Node for Secure Processing.
              </p>
            </div>
          </div>
          <div className="col-xl-3 col-sm-10 col-11 col-lg-4 col-md-6 mt-3 pt-1 mt-lg-0 pt-lg-0">
            <div className="feature_card_box feature_cards p-4 h-100">
              <img
                className="mt-2"
                height={50}
                width={50}
                src={treading}
                alt="telegram"
              />
              <p className="text-white fw-bold ff_disposable_bold lh_primary  text_lg pb-2 mb-0 mt-3 pt-1">
                Effortless Creation, Minting, and Trading.
              </p>
            </div>
          </div>
          <div className="col-xl-3 col-sm-10 col-11 col-lg-4 col-md-6 mt-3 pt-1 mt-xl-0 pt-xl-0">
            <div className="feature_card_box feature_cards p-4 h-100">
              <img
                className="mt-2"
                height={50}
                width={50}
                src={tokenBitcoin}
                alt="telegram"
              />
              <p className="text-white fw-bold ff_disposable_bold lh_primary  text_lg pb-2 mb-0 mt-3 pt-1">
                Smooth Swaps Between Rune Tokens and Bitcoin ($BTC).
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;
