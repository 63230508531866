import React from "react";
import logo from "../assets/images/png/logo.png";
const Header = () => {
  return (
    <div className="header_bg pb-3">
      <nav className="d-flex justify-content-between align-items-center container custom_container">
        <a href="/">
          <img
            height={50}
            width={158}
            className="nav_logo"
            src={logo}
            alt="logo"
          />
        </a>
        <div className="d-flex gap-sm-3 gap-md-4 nav_link">
          <a
            className="text_sm ff_emulogic opacity_80 text-decoration-none text-white"
            href="#home"
          >
            Home
          </a>
          <a
            className="text_sm ff_emulogic opacity_80 text-decoration-none text-white mx-sm-2 mx-3"
            href="#features"
          >
            Features
          </a>
          <a
            className="text_sm ff_emulogic opacity_80 text-decoration-none text-white"
            href="#contact"
          >
            Contact
          </a>
        </div>
      </nav>
    </div>
  );
};

export default Header;
